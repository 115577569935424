@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
    box-sizing: border-box;
}

body {
    background: #e4e2d5;
    margin: 0;
}

.inter-font {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
}

img {
    width: 100%;
}