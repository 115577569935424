@media screen and (max-width: 768px) {
    .inputs-content-form-main.container-form {
        width: 300px;
    }

    .form-header-content-top.inter-font {
        font-size: 15px;
    }

    .in-title-s-c.container-title {
        font-size: 14px;
    }
}