input.input-s-w {
    width: 100%;
    background: #f1f1f1;
    padding: 10px 2px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #ccc;
    /* Color del borde predeterminado */
}

.button-container {
    cursor: pointer;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: solid 1px gray;
    border-radius: 8px;
    transition: background-color 0.5s ease-in-out;
}

input.input-s-w:focus {
    outline: none;
    border: solid 1.5px #720f65;
}

.inputs-content-form-main.container-form {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 500px;
}

.content-form-box {
    width: 100%;
    margin: 10px;
    position: relative;
}

.main-content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.content-form-box.content-address-verifier {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.verifier-button.button-container {
    background-color: #3b3a6e;
    color: #ffffff;
}

.verifier-button.button-container.no-verify {
    cursor: not-allowed;
    background-color: #3b3b3b;
}

.verifier-button.button-container.verified {
    cursor: not-allowed;
    background-color: #27b94b;
}

.verifier-button.button-container.verified:hover {
    cursor: not-allowed;
    background-color: #4ed871;
}


.verifier-button.button-container.can-verify:hover {
    background-color: #605f9b;
}

.container-input-s-fixed-a {
    width: calc(100% - 150px);
}

.in-title-s-c.container-title {
    padding: 5px 0px;
}

.button-submit-info {
    background-color: #3b3a6e;
    color: #f1f1f1;
}

.button-submit-info:hover {
    background-color: #605f9b;
    color: #f1f1f1;
}

.image-content-box {
    width: 200px;
    height: 200px;
}

.header-content-s-w-h {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-header-content-top {
    text-align: center;
}

.header-content-s-w-h {
    background-color: #060428;
    padding: 10px 0px;
    border-bottom: solid 2px white;
    color: white;
    font-size: 20px;
}

.content-info-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-success-wer {
    padding: 10px;
}

.success-text {
    color: green!important;
}

.no-success {
    color: red!important;
}